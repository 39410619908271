@import 'Styles/includes';

.BillboardCampaign {
    $root: &;

    position: relative;

    // Image has to be uploaded as 16:9 if the whole image should be visible
    &__Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: black;
            opacity: .15;
        }
    }

    &__Wrap {
        @include wrap;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        padding-top: 80px;
        padding-bottom: 80px;

        @include media(M) {
            padding-top: 120px;
            padding-bottom: 120px;
        }

        @include media(ML) {
            padding-top: 152px;
            padding-bottom: 152px;
        }

        #{$root}--Right & {
            align-items: flex-end;
        }
    }

    &__Content {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 640px;
        padding: 32px 24px;
        color: $grey-90;
        border-radius: 4px;
        background: $red-5;

        @include media(M) {
            width: 50%;
            padding: 40px 32px;
        }

        @include media(ML) {
            padding: 56px 48px;
        }
    }

    &__Title {
        margin: 0;
        font-size: 3.2rem;
        font-weight: bold;
        line-height: 1.25;

        @include media(ML) {
            font-size: 4.8rem;
        }
    }

    &__Text {
        margin-top: 20px;
        font-size: 1.8rem;
        font-weight: normal;
        line-height: 1.56;
    }

    &__Button {
        #{$root} & {
            margin-top: 32px;
        }
    }
}
